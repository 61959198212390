import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import { useAuthUser } from "react-auth-kit";
import useRefreshToken from "./useRefreshToken";
import useLogout from "./useLogout";
import axios from "axios";

const useAxiosPrivate = () => {
  const useAuth = useAuthUser();
  const auth = useAuth();
  const refresh = useRefreshToken();
  const logout = useLogout();
  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers[
            "Authorization"
          ] = `Bearer ${auth?.authToken?.AccessToken}`;
        }
        if (!config.headers["mts-token"]) {
          config.headers["mts-token"] = auth?.authToken?.IdToken;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        let newAccessToken = await refresh();
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          if (newAccessToken !== false) {
            prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
            prevRequest.sent = true;
            return axios.request(error.config);
          } else {
            logout();
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.use(requestIntercept);
      axiosPrivate.interceptors.response.use(responseIntercept);
    };
  }, [auth]);

  return axiosPrivate;
};

export default useAxiosPrivate;
