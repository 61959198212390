import { Stack, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import MenuItem from "@mui/material/MenuItem";
export default function DynamicForm({ onChange, params }) {
  const [t] = useTranslation("global");

  const [data, setData] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    axiosPrivate.get(`merchants/bank-account/form`).then((response) => {
      setData(response.data);
    });
  }, []);

  return (
    <Stack spacing={2}>
      {data.map((element, index) => {
        if (element.type == "text") {
          return (
            <TextField
              key={index}
              required={element.required}
              id={element.id}
              label={t(`Account.Dinamic.${element.placeholder_key}`)}
              defaultValue={element.default}
              variant={element.variant}
              onChange={onChange}
              name={element.key}
              margin="dense"
              size="small"
              InputLabelProps={{
                style: { color: "#00377D", fontSize: 14 },
                required: false,
              }}
              InputProps={{
                style: {
                  fontSize: 14,
                  color: "#404A56",
                  fontWeight: 400,
                  lineHeight: "16.41px",
                },
              }}
            />
          );
        } else if (element.type == "select" && element.id == "bank-name-id") {
          return (
            <div>
              <TextField
                value={params["bank-name"] ? params["bank-name"] : ""}
                label={t(`Account.Dinamic.bank`)}
                select
                size="small"
                style={{
                  width: "100%",
                  fontSize: "14px",
                  color: "#404A56",
                  fontWeight: 400,
                  lineHeight: "16px",
                }}
                InputLabelProps={{
                  style: { color: "#00377D", fontSize: 14 },
                  required: false,
                }}
                InputProps={{
                  style: {
                    fontSize: 14,
                    color: "#404A56",
                    fontWeight: 400,
                    lineHeight: "16.41px",
                  },
                }}
                id={element.id}
                data-testid="select-bank"
                name={element.key}
                onChange={onChange}
              >
                {element.data_select.map((resp) => {
                  return (
                    <MenuItem key={resp.id} value={resp.id}>
                      {resp.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
          );
        } else if (element.type == "select" && element.id == "account-type") {
          return (
            <div>
              <TextField
                id={element.id}
                select
                size="small"
                data-testid="select-account"
                style={{
                  width: "100%",
                  fontSize: "14px",
                  color: "#404A56",
                  fontWeight: 400,
                }}
                InputLabelProps={{
                  style: { color: "#00377D", fontSize: 14 },
                  required: false,
                }}
                InputProps={{
                  style: {
                    fontSize: 14,
                    color: "#404A56",
                    fontWeight: 400,
                    lineHeight: "16.41px",
                  },
                }}
                name={element.key}
                value={params["account-type"] ? params["account-type"] : ""}
                label={t(`Account.Dinamic.accountType`)}
                onChange={onChange}
              >
                {element.data_select.map((resp) => {
                  return (
                    <MenuItem key={resp.id} value={resp.id}>
                      {resp.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
          );
        } else if (element.type == "select" && element.id == "bank-type-id") {
          return (
            <div key={element.id}>
              <TextField
                id={element.id}
                select
                size="small"
                data-testid="select-bank-type-id"
                style={{
                  width: "100%",
                  fontSize: "14px",
                  color: "#404A56",
                  fontWeight: 400,
                }}
                InputLabelProps={{
                  style: { color: "#00377D", fontSize: 14 },
                  required: false,
                }}
                InputProps={{
                  style: {
                    fontSize: 14,
                    color: "#404A56",
                    fontWeight: 400,
                    lineHeight: "16.41px",
                  },
                }}
                name={element.key}
                value={params["bank-type-id"]}
                label={t(`Account.Dinamic.bankType`)}
                onChange={onChange}
              >
                {element.data_select.map((elem) => {
                  return (
                    <MenuItem key={elem.id} value={elem.id}>
                      {elem.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
          );
        }
      })}
    </Stack>
  );
}
