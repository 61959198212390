import React from "react";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { currencyFormatter } from "../../utils/validateExpression";
import PermissionsGate from "../commons/PermissionsGate";

function CardStyle(title, subTitle, value = 0, value2 = 0) {
  const [t] = useTranslation("global");
  return (
    <Card
      style={{
        width: "100vh",
        height: "112px",
        borderRadius: "16px",
        padding: 0,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          padding: "8px 16px 8px 23px",
          position: "absolute",
          background: "#FABE00",
          borderRadius: "16px 0 8px 0",
        }}
      >
        <Typography
          sx={{ color: "#404a56", fontSize: "14px", fontWeight: "600" }}
        >
          {title}
        </Typography>
      </CardContent>
      <Stack direction="row" width="100%" justifyContent="end" mt={4}>
        <Typography
          sx={{
            margin: "0 24px",
            color: "#404a56",
            fontSize: "28px",
            fontWeight: "500",
          }}
        >
          {currencyFormatter(value, t("Locales.local"), t("Locales.currency"))}
        </Typography>
      </Stack>
      <Stack direction="row" width="100%" justifyContent="end">
        <Typography
          sx={{
            margin: "0 24px",
            color: "#708195",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          {subTitle +
            " " +
            currencyFormatter(
              value2,
              t("Locales.local"),
              t("Locales.currency")
            )}
        </Typography>
      </Stack>
    </Card>
  );
}

const BalanceDetail = ({ balance }) => {
  const [t] = useTranslation("global");

  return (
    <PermissionsGate scopes={"resources.insights.actions.cards"}>
      <Stack direction="row" width="100%" spacing={3} mt={4}>
        {CardStyle(
          t("Insights.Date.Today"),
          t("Insights.Date.Yesterday"),
          balance.today,
          balance.yesterday
        )}
        {CardStyle(
          t("Insights.Date.Month"),
          t("Insights.Date.PreviousMonth"),
          balance.thisMonth,
          balance.previousMonth
        )}
        {CardStyle(
          t("Insights.Date.Available"),
          t("Insights.Date.Retired"),
          balance.available,
          balance.withdrawn
        )}
      </Stack>
    </PermissionsGate>
  );
};

export default BalanceDetail;
