import React, { useEffect } from "react";
import OnBoardingLayout from "../components/on-boarding-layout";
import { useLocation } from "react-router-dom";
import GenericMessage from "../components/genericMessage";
import useLogout from "../hooks/useLogout";

export default function MessageScreen() {
  const location = useLocation();
  const type = location.state.type;
  const logout = useLogout();
  const signOut = async () => {
    logout();
  };
  useEffect(() => {
    signOut();
  }, []);
  return (
    <OnBoardingLayout
      screen={<GenericMessage type={type} />}
      withoutContainer
    />
  );
}
