import React, { useEffect, Fragment } from "react";
import Iframe from "react-iframe";
import { Container } from "@mui/material";
import { useSignIn } from "react-auth-kit";
import { getMtsTokenId } from "./utils/jwt";
import { useNavigate, useLocation } from "react-router-dom";
import dataJson from "./config/signIn.json";
import { schema } from "./utils/yup";
const LoginV2 = () => {
  const signIn = useSignIn();
  const navigate = useNavigate();
  const location = useLocation();
  let fromSave = localStorage.getItem("from");

  useEffect(() => {
    localStorage.setItem(
      "from",
      location.state?.from?.pathname ? location.state?.from?.pathname : "/"
    );
    let _validates = {};
    dataJson.forms.fields.forEach(({ props, validations }) => {
      _validates[props.name] = schema(validations, props.type);
    });
  }, []);

  const getFront = (sign_contract, pending, mtsToke, RefreshToken) => {
    let from = fromSave ?? "";
    if (sign_contract) {
      if (mtsToke?.RESET === "1") {
        from = "/ResetPassword";
      } else {
        localStorage.setItem("RefreshToken", RefreshToken);
      }
      return from;
    }
    return `/${pending}`;
  };

  useEffect(() => {
    let from = "";
    window.addEventListener("message", (e) => {
      if (e.origin === process.env.REACT_APP_INTEGRATOR_DOMAIN) {
        if (e.data.message == "LOGIN") {
          let mtsToke = getMtsTokenId(e.data.data.authToken.IdToken);
          signIn({
            token: e.data.data.authToken.AccessToken,
            tokenType: "Bearer",
            expiresIn: e.data.data.authToken.ExpiresIn,
            authState: {
              country: e.data.data.country,
              name: e.data.data.fullName,
              roleName: e.data.data.roleName,
              roles: e.data.data.roles,
              authToken: e.data.data.authToken,
              email: e.data.data.email,
              msisdn: e.data.data.msisdn,
              userId: e.data.data.user_id,
              businessName: e.data.data.business_name,
              IdToken: mtsToke,
              pending: e.data.data.pending,
            },
            refreshToken: e.data.data.authToken.RefreshToken,
            refreshTokenExpireIn: e.data.data.authToken.ExpiresIn,
          });
          from = getFront(
            e.data.data.sign_contract,
            e.data.data.pending,
            mtsToke,
            e.data.data.authToken.RefreshToken
          );
          navigate(from, {
            replace: true,
            screen: from,
            state: {
              email: e.data.data.email,
              device: mtsToke?.DEVICE_ID,
              client: mtsToke?.CLIENT_ID,
              type: e.data.data.data,
              userId: e.data.data.user_id,
              contract_flow: e.data.data.contract_flow,
            },
          });
        } else if (e.data.message == "FORGOT") {
          from = "/ForgotPassword";
          navigate(from, {
            replace: true,
            screen: from,
          });
        }
      }
    });
  }, []);

  return (
    <Fragment>
      <Container
        sx={{ height: "100%", position: "absolute", padding: "0 !important" }}
        maxWidth={false}
      >
        <Iframe
          url={process.env.REACT_APP_INTEGRATOR_DOMAIN + "/signIn"}
          width="100%"
          height="950px"
          className="iFrame"
        />
      </Container>
    </Fragment>
  );
};
export default LoginV2;
